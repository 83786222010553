import { Component, OnInit } from '@angular/core';
import { TranslationHelperService } from 'src/app/services/translations/translation-helper.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  termsRouterLink = 'terms'
  constructor(
    protected translationHelper: TranslationHelperService,
  ) {
   }

  ngOnInit() {
  }
}
